import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';

interface CustomMarkerProps {
  coordinates: [number, number];
  map: mapboxgl.Map | null;
  children: React.ReactNode;
  marker: React.ReactNode;
  minWidth?: string;
}

const CustomMarker: React.FC<CustomMarkerProps> = (props) => {
  const markerContainerRef = useRef<HTMLDivElement | null>(null);
  const popupContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!props.map) return;

    // Create a new DOM node and save it to the React ref
    markerContainerRef.current = document.createElement('div');

    createRoot(markerContainerRef.current).render(props.marker);

    // Create a new DOM node for the popup content
    popupContainerRef.current = document.createElement('div');

    if (props.minWidth) {
      popupContainerRef.current.style.minWidth = props.minWidth;
    }

    createRoot(popupContainerRef.current).render(props.children);

    const marker = new mapboxgl.Marker(markerContainerRef.current)
      .setLngLat([props.coordinates[0], props.coordinates[1]])
      .setPopup(new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupContainerRef.current))
      .addTo(props.map);

    return () => {
      marker.remove();
    };
  }, []);

  return null;
};

export default CustomMarker;
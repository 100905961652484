// Third-party
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'normalize.css';

// Components
import { ADT } from 'services/adt';
import { Factory } from 'pages/Factory';
import { Dashboard } from 'pages/Dashboard';
import { Layout } from 'components/Layout';

// Styles
import 'styles/global.scss';
import 'styles/fonts.scss';
import ReportPage from 'pages/Report/ReportPage';
import QlikSense from 'pages/Qliksense/QlikSense';

const App = () => {
  const adtService = new ADT();
  const [demoStage, setDemoStage] = useState<number>(1);
  const [selectedOrder, setSelectedOrder] = useState<any>(null); // Specify the type instead of any if possible
  const [demoIsLive, setDemoIsLive] = useState<boolean>(true);
  const [data, setData] = useState({
    pakistanFactoryData: adtService.getTwin('CA44210'),
    southAmericaFactoryData: adtService.getTwin('CA44220'),
    usRetailData: adtService.getTwin('CA4465879'),
    ukRetailData: adtService.getTwin('CA4461698'),
    atlanticOceanShipmentData: adtService.getTwin('CA44901-22'),
    indianOceanShipmentData: adtService.getTwin('CA91134-88'),
    factoryLinesData: adtService.getFactoryData(),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const newData = { ...data } as any;
      Object.keys(newData).forEach((key) => {
        if (newData[key].liveValues) {
          newData[key].liveValues.forEach((v: any) => {
            const plusOrMinus = Math.random() < 0.5 ? -1 : 1;
            const fluctuation = plusOrMinus * Math.random();
            const newVal = parseFloat(newData[key][v.title]) + fluctuation;
            if (v.min < newVal && newVal < v.max) {
              newData[key][v.title] = newVal.toFixed(1);
            }
          });
        }
      });
      newData.factoryLinesData.forEach((line: any) => {
        line.assets.forEach((a: any) => {
          if (a.liveValues) {
            a.liveValues.forEach((v: any) => {
              const plusOrMinus = Math.random() < 0.5 ? -1 : 1;
              const fluctuation = plusOrMinus * Math.random();
              const newVal = parseFloat(a[v.title]) + fluctuation;
              if (v.min < newVal && newVal < v.max) {
                a[v.title] = newVal.toFixed(1);
              }
            });
          }
        });
      });
      // Assuming `estimatedtimeofarrival` is a moment object or similar
      newData.atlanticOceanShipmentData.estimatedtimeofarrival?.subtract('2', 'seconds');
      newData.indianOceanShipmentData.estimatedtimeofarrival?.subtract('2', 'seconds');
      setData(newData);
    }, 2000);

    return () => clearInterval(interval);
  }, [data, demoIsLive]);

  const setFactoryLinesData = (lines: any) => {
    // Specify the type instead of any if possible
    setData((prevData) => ({ ...prevData, factoryLinesData: lines }));
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout setDemoStage={setDemoStage} showTopBar={true} demoIsLive={demoIsLive} isDashboard={true} demoStage={demoStage}>
            <Dashboard
              demoStage={demoStage}
              setDemoStage={setDemoStage}
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
              data={data}
              demoIsLive={demoIsLive}
              setDemoIsLive={setDemoIsLive}
            />
          </Layout>
        }
      />
      <Route
        path="/factory"
        element={
          <Layout setDemoStage={setDemoStage} showTopBar={false} demoIsLive={demoIsLive} isDashboard={true} demoStage={demoStage}>
            <Factory
              setDemoStage={setDemoStage}
              data={data}
              setFactoryLinesData={setFactoryLinesData}
              setDemoIsLive={setDemoIsLive}
              demoIsLive={demoIsLive}
            />
          </Layout>
        }
      />
      <Route
        path="/qlik-sense"
        element={
          <Layout setDemoStage={setDemoStage} showTopBar={false} demoIsLive={demoIsLive} isDashboard={true} demoStage={demoStage}>
            <QlikSense />
          </Layout>
        }
      />
      <Route path="/reports/:id" element={<ReportPage />} />
    </Routes>
  );
};

export default App;

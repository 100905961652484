import { Gauge } from "components/Gauge";
import ShipmentIcon from "assets/Transport-Ship.svg";
import Svg from 'react-inlinesvg';

interface Props {
  data: any;
  showGaugeData: any;
}

const ShipModal: React.FC<Props> = (props) => {
  const { indianOceanShipmentData } = props.data;
  return (
    <div>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="ship-icon">
          <Svg src={ShipmentIcon} />
        </div>
        <div className="title">
          <h3>Twin Type: Shipment</h3>
          <span>ID: {indianOceanShipmentData.id}</span>
        </div>
      </header>
      <div style={{ paddingBottom: 5 }}>
        <div className="gauge">
          <Gauge
            value={props.showGaugeData ? indianOceanShipmentData.humidity : 0}
            min={indianOceanShipmentData.minHumidity}
            max={indianOceanShipmentData.maxHumidity}
          />
          <div className="title">Humidity</div>
        </div>
        <div className="twin-data">
          <div>
            <div className="property-title">ETA:</div>
            <div className="property-value">{indianOceanShipmentData.estimatedtimeofarrival.format('l LTS')}</div>
          </div>
          <div>
            <div className="property-title">Status:</div>
            <div className="property-value">{indianOceanShipmentData.status ? indianOceanShipmentData.status : 'On time'}</div>
          </div>
          <div>
            <div className="property-title">Location:</div>
            <div className="property-value">
              {indianOceanShipmentData.location ? indianOceanShipmentData.location : '39.5771, -40.1922'}
            </div>
          </div>
          <div>
            <div className="property-title">Temp:</div>
            <div className="property-value">
              {indianOceanShipmentData.temperature}
              {indianOceanShipmentData.temperature ? '°F' : ''}
              <span className="property-info">(Max 100.8°F)</span>
            </div>
          </div>
          <div>
            <div className="property-title">Vibration:</div>
            <div className="property-value">
              {indianOceanShipmentData.vibration}
              {indianOceanShipmentData.vibration ? 'Hz' : ''}
              <span className="property-info">(Max 160.5Hz)</span>
            </div>
          </div>
        </div>
      </div>
      <div className="button-full-width">
        <span>View Shipment</span>
      </div>
    </div>
  )
};

export default ShipModal;
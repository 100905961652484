import React, { useEffect } from 'react';
import './css/style.css';
import expandIcon from './img/expand.svg';
import logoIcon from './img/logo.png';

const QlikSense = () => {
  const iframeStyle = {
    border: 'none',
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    const mainElement = document.querySelector('.ai_widget_section');
    if (mainElement) {
      // display: block;
      mainElement.style.display = 'block';
      document.getElementById('report-generator').innerHTML = 'Qliksense Report Generator';
      document.getElementById('report-summarization').innerHTML = 'Qliksense Summarization';
      document.getElementById('report-data-analytics').innerHTML = 'Qliksense Data Analytics';
      document.getElementById('report-generator-1').innerHTML = 'Qliksense Report Generator-1';
    }
  }, []);

  return (
    <div className="position-relative h-100">
      <div id="particle-canvas" className="h-100 w-100 position-absolute start-0 end-0 top-0 bottom-0 opacity-50"></div>

      <div className="contentbox position-relative">
        <header className="header d-flex   justify-content-between align-items-center">
          <div className="toplogo bg-transparent">
            <a className="navbar-brand bg-transparent p-0 mx-auto" href="#">
              <img src={logoIcon} alt="Logo" className="img-fluid" />
            </a>
          </div>

          <div className="rightbtn bg-transparent border-0 d-flex align-items-center ">
            {/* <ul className="filterlist p-0 m-0 d-flex">
          
          <li className="d-flex align-items-center mx-2">
            <select className="form-select">
              <option value="1">English</option>
              <option value="1">Arabic</option>
            </select>
          </li>
        </ul>  */}
            <ul className="list-group-1 list-unstyled d-flex list-group-horizontal mx-0 ms-0 p-0 m-0 align-items-center">
              <li className="list-group-item-1">
                <a
                  className="qcmd top-bar-btn"
                  data-qcmd="clearAll"
                  title="Clear Selection"
                  style={{ cursor: 'pointer', color: 'white' }}
                  data-bs-toggle="modal"
                  data-bs-target="#DetailsView"
                >
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.83554 0.21582C1.81142 0.21582 0.13623 1.87898 0.13623 3.9031V26.0628C0.13623 28.087 1.81142 29.7501 3.83554 29.7501H19.8321H25.9832C28.0073 29.7501 29.6729 28.087 29.6729 26.0628V3.9031C29.6729 1.87898 28.0073 0.21582 25.9832 0.21582H3.83554ZM2.6 9.44604H8.74867V13.7511H2.6V9.44604ZM11.2125 9.44604H18.5967V13.7511H11.2125V9.44604ZM21.0604 9.44604H27.2091V13.7511H21.0604V9.44604ZM2.6 16.2149H8.74867V20.5199H2.6V16.2149ZM11.2125 16.2149H18.5967V20.5199H11.2125V16.2149ZM21.0604 16.2149H27.2091V20.5199H21.0604V16.2149ZM2.6 22.9813H8.74867V27.2887H3.83554C3.13243 27.2887 2.6 26.7659 2.6 26.0628V22.9813ZM11.2125 22.9813H18.5967V27.2887H11.2125V22.9813ZM21.0604 22.9813H27.2091V26.0628C27.2091 26.7659 26.6863 27.2887 25.9832 27.2887H21.0604V22.9813Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </header>

        <div className="offcanvas offcanvas-end customsidebar" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              SideMenu
            </h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div>Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.</div>
          </div>
        </div>

        <div className="bottomcontent">
          <div className="container-fluid ">
            {/* 
      <div className="row gx-2 mb-3" >
        <div className="col-xl-9 col-lg-8 col-md-7">
          <div className="leftdatebar mb-3 mb-xl-0 mb-md-0 mb-lg-0">
            <iframe src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=03b4bd0c-4fbf-4e3b-a1ed-5993ddaf8c5b&theme=cold%20days&opt=ctxmenu&select=$::%23LANGUAGE,English" style={iframeStyle}></iframe>
            
           <ul className="list-unstyled d-flex m-0 p-0 align-items-center" >
              <li>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="datefilter"  value="" id="flexCheckChecked" />
                  <label className="form-check-label" for="flexCheckChecked">
                    2019
                  </label>
                </div>
              </li>
              <li>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="datefilter"  value="" id="flexCheckChecked1" />
                  <label className="form-check-label" for="flexCheckChecked1">
                    2020
                  </label>
                </div>
              </li>
              <li>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="datefilter"  value="" id="flexCheckChecked2" />
                  <label className="form-check-label" for="flexCheckChecked2">
                    2021
                  </label>
                </div>
              </li>
              <li>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="datefilter"  value="" id="flexCheckChecked3"/> 
                  <label className="form-check-label" for="flexCheckChecked3">
                    2022
                  </label>
                </div>
              </li>
              <li>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="datefilter"  value="" id="flexCheckChecked4" checked />
                  <label className="form-check-label" for="flexCheckChecked4">
                    2023
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-5">
          <div className="rightselect rightbtn bg-transparent border-0 d-flex align-items-center ">
            <ul className="filterlist p-0 m-0 d-flex">
              
              <li className="d-flex align-items-center ">
                <select className="form-select">
                  <option value="1">Date</option>
                  <option value="1">Date</option>
                </select>
              </li>
              <li className="d-flex align-items-center mx-2">
                <select className="form-select">
                  <option value="1">Activity Name</option>
                  <option value="1">Activity Name</option>
                </select>
              </li>
            </ul>
        
          </div>
        </div>
      </div> */}

            <div className="customrow">
              <div className="row gx-3 mb-0 ">
                <div className="col-xl-4 col-md-6">
                  <div className="whiteBox pt-3 h-100">
                    <div className="boxHeader px-2 py-1">
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-0 pt-0">
                          {' '}
                          <span className="d-block lh-sm">Activity - Process Plot</span>
                        </h3>

                        <button
                          className="fullscreen-icon bg-transparent p-0 shadow-none border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#engineoutput"
                        >
                          <img src={expandIcon} className="img-fluid" />
                        </button>
                      </div>
                    </div>
                    <div className="boxContainer p-2">
                      <iframe
                        src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=7e3ac4d0-e38f-4b7b-b93b-ba082215768f&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                        style={iframeStyle}
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="whiteBox pt-3 h-100">
                    <div className="boxHeader px-2 py-1">
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-0 pt-0">
                          {' '}
                          <span className="d-block lh-sm">Monthly Activity</span>
                        </h3>

                        <button
                          className="fullscreen-icon bg-transparent p-0 shadow-none border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#monthACT1"
                        >
                          <img src={expandIcon} className="img-fluid" />
                        </button>
                      </div>
                    </div>
                    <div className="boxContainer p-2">
                      <iframe
                        src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=3afb1a24-e629-417c-b12a-406dbf16b25d&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                        style={iframeStyle}
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="whiteBox pt-3 h-100">
                    <div className="boxHeader px-2 py-1">
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-0 pt-0">
                          {' '}
                          <span className="d-block lh-sm">Activity - Folio %</span>
                        </h3>

                        <button
                          className="fullscreen-icon bg-transparent p-0 shadow-none border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#activity1"
                        >
                          <img src={expandIcon} className="img-fluid" />
                        </button>
                      </div>
                    </div>
                    <div className="boxContainer p-2">
                      <iframe
                        src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=9b05f419-e9f5-4971-9dbe-8e796754ab0a&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                        style={iframeStyle}
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-md-6">
                  <div className="whiteBox pt-3 h-100 mb-0">
                    <div className="boxHeader px-2 py-1">
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-0 pt-0">
                          {' '}
                          <span className="d-block lh-sm">Periodic - Folio Count</span>
                        </h3>

                        <button
                          className="fullscreen-icon bg-transparent p-0 shadow-none border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#TrendsAcross"
                        >
                          <img src={expandIcon} className="img-fluid" />
                        </button>
                      </div>
                    </div>
                    <div className="boxContainer p-2">
                      <iframe
                        src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=ad0a7cce-26f6-43cb-9b84-841ca356e490&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                        style={iframeStyle}
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-12 col-md-12">
                  <div className="whiteBox pt-3 h-100  mb-0">
                    <div className="boxHeader px-2 py-1">
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-0 pt-0">
                          {' '}
                          <span className="d-block lh-sm">Flow Chart Across Activity / Process Area / Month</span>
                        </h3>

                        <button
                          className="fullscreen-icon bg-transparent p-0 shadow-none border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#TrendsAcross1"
                        >
                          <img src={expandIcon} className="img-fluid" />
                        </button>
                      </div>
                    </div>
                    <div className="boxContainer p-2">
                      <iframe
                        src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=c8ab2ace-93c7-4c73-ab30-c51483ac5958&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                        style={iframeStyle}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Qlik Popups */}

      <div className="modal fade ctmmodal" id="engineoutput" tabindex="-1" aria-labelledby="engineoutput" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content border-secondary h-auto">
            <div className="modal-header d-block">
              <h5 className="fw-bold mt-2 mb-0 fs-3">Activity - Process Plot</h5>
              <button type="button" className="btn-close text-white opacity-100" data-bs-dismiss="modal" aria-label="Close">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.344041 0.860276C0.734565 0.469752 1.36773 0.469752 1.75825 0.860276L7.67808 6.78011L13.5979 0.860276C13.9884 0.469752 14.6216 0.469752 15.0121 0.860276C15.4027 1.2508 15.4027 1.88397 15.0121 2.27449L9.0923 8.19432L15.0121 14.1142C15.4027 14.5047 15.4027 15.1378 15.0121 15.5284C14.6216 15.9189 13.9884 15.9189 13.5979 15.5284L7.67808 9.60853L1.75825 15.5284C1.36773 15.9189 0.734565 15.9189 0.344041 15.5284C-0.0464836 15.1378 -0.0464836 14.5047 0.344041 14.1142L6.26387 8.19432L0.344041 2.27449C-0.0464836 1.88397 -0.0464836 1.2508 0.344041 0.860276Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body p-3">
              <div className="chartSectionFull  align-items-start justify-content-start d-block bg-white rounded-3 p-5">
                <iframe
                  src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=7e3ac4d0-e38f-4b7b-b93b-ba082215768f&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                  style={iframeStyle}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade ctmmodal" id="monthACT1" tabindex="-1" aria-labelledby="monthACT1" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content border-secondary h-auto">
            <div className="modal-header d-block">
              <h5 className="fw-bold mt-2 mb-0 fs-3">Monthly Activity</h5>
              <button type="button" className="btn-close text-white opacity-100" data-bs-dismiss="modal" aria-label="Close">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.344041 0.860276C0.734565 0.469752 1.36773 0.469752 1.75825 0.860276L7.67808 6.78011L13.5979 0.860276C13.9884 0.469752 14.6216 0.469752 15.0121 0.860276C15.4027 1.2508 15.4027 1.88397 15.0121 2.27449L9.0923 8.19432L15.0121 14.1142C15.4027 14.5047 15.4027 15.1378 15.0121 15.5284C14.6216 15.9189 13.9884 15.9189 13.5979 15.5284L7.67808 9.60853L1.75825 15.5284C1.36773 15.9189 0.734565 15.9189 0.344041 15.5284C-0.0464836 15.1378 -0.0464836 14.5047 0.344041 14.1142L6.26387 8.19432L0.344041 2.27449C-0.0464836 1.88397 -0.0464836 1.2508 0.344041 0.860276Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body p-3">
              <div className="chartSectionFull  align-items-start justify-content-start d-block bg-white rounded-3 p-5">
                <iframe
                  src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=3afb1a24-e629-417c-b12a-406dbf16b25d&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                  style={iframeStyle}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade ctmmodal" id="activity1" tabindex="-1" aria-labelledby="activity1" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content border-secondary h-auto">
            <div className="modal-header d-block">
              <h5 className="fw-bold mt-2 mb-0 fs-3">Activity - Process Plot</h5>
              <button type="button" className="btn-close text-white opacity-100" data-bs-dismiss="modal" aria-label="Close">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.344041 0.860276C0.734565 0.469752 1.36773 0.469752 1.75825 0.860276L7.67808 6.78011L13.5979 0.860276C13.9884 0.469752 14.6216 0.469752 15.0121 0.860276C15.4027 1.2508 15.4027 1.88397 15.0121 2.27449L9.0923 8.19432L15.0121 14.1142C15.4027 14.5047 15.4027 15.1378 15.0121 15.5284C14.6216 15.9189 13.9884 15.9189 13.5979 15.5284L7.67808 9.60853L1.75825 15.5284C1.36773 15.9189 0.734565 15.9189 0.344041 15.5284C-0.0464836 15.1378 -0.0464836 14.5047 0.344041 14.1142L6.26387 8.19432L0.344041 2.27449C-0.0464836 1.88397 -0.0464836 1.2508 0.344041 0.860276Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body p-3">
              <div className="chartSectionFull  align-items-start justify-content-start d-block bg-white rounded-3 p-5">
                <iframe
                  src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=9b05f419-e9f5-4971-9dbe-8e796754ab0a&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                  style={iframeStyle}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade ctmmodal" id="TrendsAcross" tabindex="-1" aria-labelledby="TrendsAcross" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content border-secondary h-auto">
            <div className="modal-header d-block">
              <h5 className="fw-bold mt-2 mb-0 fs-3">Periodic - Folio Count</h5>
              <button type="button" className="btn-close text-white opacity-100" data-bs-dismiss="modal" aria-label="Close">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.344041 0.860276C0.734565 0.469752 1.36773 0.469752 1.75825 0.860276L7.67808 6.78011L13.5979 0.860276C13.9884 0.469752 14.6216 0.469752 15.0121 0.860276C15.4027 1.2508 15.4027 1.88397 15.0121 2.27449L9.0923 8.19432L15.0121 14.1142C15.4027 14.5047 15.4027 15.1378 15.0121 15.5284C14.6216 15.9189 13.9884 15.9189 13.5979 15.5284L7.67808 9.60853L1.75825 15.5284C1.36773 15.9189 0.734565 15.9189 0.344041 15.5284C-0.0464836 15.1378 -0.0464836 14.5047 0.344041 14.1142L6.26387 8.19432L0.344041 2.27449C-0.0464836 1.88397 -0.0464836 1.2508 0.344041 0.860276Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body p-3">
              <div className="chartSectionFull  align-items-start justify-content-start d-block bg-white rounded-3 p-5">
                <iframe
                  src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=ad0a7cce-26f6-43cb-9b84-841ca356e490&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                  style={iframeStyle}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade ctmmodal" id="TrendsAcross1" tabindex="-1" aria-labelledby="TrendsAcross1" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content border-secondary h-auto">
            <div className="modal-header d-block">
              <h5 className="fw-bold mt-2 mb-0 fs-3">Flow Chart Across Activity / Process Area / Month</h5>
              <button type="button" className="btn-close text-white opacity-100" data-bs-dismiss="modal" aria-label="Close">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.344041 0.860276C0.734565 0.469752 1.36773 0.469752 1.75825 0.860276L7.67808 6.78011L13.5979 0.860276C13.9884 0.469752 14.6216 0.469752 15.0121 0.860276C15.4027 1.2508 15.4027 1.88397 15.0121 2.27449L9.0923 8.19432L15.0121 14.1142C15.4027 14.5047 15.4027 15.1378 15.0121 15.5284C14.6216 15.9189 13.9884 15.9189 13.5979 15.5284L7.67808 9.60853L1.75825 15.5284C1.36773 15.9189 0.734565 15.9189 0.344041 15.5284C-0.0464836 15.1378 -0.0464836 14.5047 0.344041 14.1142L6.26387 8.19432L0.344041 2.27449C-0.0464836 1.88397 -0.0464836 1.2508 0.344041 0.860276Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body p-3">
              <div className="chartSectionFull  align-items-start justify-content-start d-block bg-white rounded-3 p-5">
                <iframe
                  src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=c8ab2ace-93c7-4c73-ab30-c51483ac5958&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                  style={iframeStyle}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade ctmmodal" id="DetailsView" tabindex="-1" aria-labelledby="DetailsView" aria-hidden="true">
        <div className="modal-dialog  modal-fullscreen">
          <div className="modal-content border-secondary h-auto">
            <div className="modal-header d-block">
              <h5 className="fw-bold mt-2 mb-0 fs-3">Detail View</h5>
              <button type="button" className="btn-close text-white opacity-100" data-bs-dismiss="modal" aria-label="Close">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.344041 0.860276C0.734565 0.469752 1.36773 0.469752 1.75825 0.860276L7.67808 6.78011L13.5979 0.860276C13.9884 0.469752 14.6216 0.469752 15.0121 0.860276C15.4027 1.2508 15.4027 1.88397 15.0121 2.27449L9.0923 8.19432L15.0121 14.1142C15.4027 14.5047 15.4027 15.1378 15.0121 15.5284C14.6216 15.9189 13.9884 15.9189 13.5979 15.5284L7.67808 9.60853L1.75825 15.5284C1.36773 15.9189 0.734565 15.9189 0.344041 15.5284C-0.0464836 15.1378 -0.0464836 14.5047 0.344041 14.1142L6.26387 8.19432L0.344041 2.27449C-0.0464836 1.88397 -0.0464836 1.2508 0.344041 0.860276Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body p-3">
              <div className="chartSectionFull  align-items-start justify-content-start d-block bg-white rounded-3 p-0">
                <iframe
                  src="https://bklxjl4dma45swm.sg.qlikcloud.com/single/?appid=31f30a38-732e-4135-973d-5dd663891be9&obj=WgmPr&theme=exiga%20color%20v1&opt=ctxmenu&select=$::%23LANGUAGE,English"
                  style={iframeStyle}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QlikSense;

// Node_modules
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Svg from 'react-inlinesvg';
import moment from 'moment';
import NavIcon1 from 'assets/worldmap.svg';
import NavIcon2 from 'assets/offshore.svg';
import NavIcon3 from 'assets/shipping.svg';
import NavIcon4 from 'assets/health.svg';
import NavIcon5 from 'assets/monitoring.svg';
import NavIcon6 from 'assets/settings.svg';
import HistoryIcon from 'assets/IconHistorical.svg';
import IconTee from 'assets/refined.svg';
import IconHat from 'assets/retailsfuels.svg';
import IconJacket from 'assets/gasproduction.svg';
import IconPants from 'assets/oilproduction.svg';
import IconShoe from 'assets/chemicals.svg';
import ContosoBrand from 'assets/ContosoDark.svg';
import LogoIcon from 'assets/logoicon.svg';
import NavIcon7 from "assets/dashboardicon.svg";
import TeamsNotification from 'assets/TeamsNotification.svg';
import { ApiService } from 'services/apiService';

// Local imports
import './Layout.scss';

/* eslint max-lines-per-function : 0 */

export const Layout = ({ children, setDemoStage, demoStage, showTopBar = true, demoIsLive, isDashboard = false }) => {
  const [showTeamsToast, setShowTeamsToast] = useState(false);
  const [animateToastClose, setAnimateToastClose] = useState(false);
  const [topBarItemIsSelected, setTopBarItemIsSelected] = useState(false);
  const [time, setTime] = useState(moment().format('l LTS'));

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.keyCode) {
        case 32:
          setShowTeamsToast(true);
          break;
        case 68:
          ApiService.startSimulation();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    const timeInterval = setInterval(() => {
      setTime(moment().format('l LTS'));
    }, 1000);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      clearInterval(timeInterval);
    };
  }, []);

  useEffect(() => {
    if (!window.tsiData) {
      ApiService.getTsiData().then((data) => {
        window.tsiData = data;
      });
    }
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Step 3: Handle the toggle function
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="layout-wrap">
      <nav className={`sidebar ${isSidebarOpen ? 'open' : 'close'}`}>
        <div className={`arrowicon ${isSidebarOpen ? 'open' : 'close'}`} onClick={toggleSidebar}>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.905504 5.20041C0.742033 5.36393 0.650201 5.58568 0.650201 5.8169C0.650201 6.04812 0.742033 6.26987 0.905504 6.43339L5.83828 11.3662C5.91872 11.4494 6.01494 11.5159 6.12132 11.5616C6.22771 11.6073 6.34213 11.6313 6.45791 11.6323C6.57369 11.6333 6.68851 11.6113 6.79567 11.5674C6.90284 11.5236 7.00019 11.4588 7.08207 11.377C7.16394 11.2951 7.22869 11.1977 7.27253 11.0906C7.31637 10.9834 7.33844 10.8686 7.33743 10.7528C7.33642 10.637 7.31237 10.5226 7.26667 10.4162C7.22097 10.3098 7.15454 10.2136 7.07126 10.1332L2.75497 5.8169L7.07126 1.50061C7.2301 1.33615 7.31799 1.11589 7.316 0.887258C7.31401 0.658628 7.22231 0.439926 7.06064 0.278254C6.89897 0.116582 6.68026 0.0248774 6.45163 0.0228906C6.223 0.0209039 6.00274 0.108794 5.83828 0.267632L0.905504 5.20041Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="logobar">
          <Link to="/" className="nav-brand fulllogo">
            <Svg src={ContosoBrand} />
          </Link>
          <Link to="/" className="Logoicon">
            <Svg src={LogoIcon} />
          </Link>
        </div>
        <ul className="sidemenu">
          <li className="is-selected">
            <Link to="/">
              <Svg src={NavIcon1} />

              <span className="nav-text" style={{ fontWeight: '400' }}>World Map</span>
            </Link>
          </li>

          <li>
            <Link to="/">
              <Svg src={NavIcon2} style={{ fill: 'currentColor' }} />

              <span className="nav-text">Off Shore Oil Rigs</span>
            </Link>
          </li>

          <li>
            <Link to="/">
              <Svg src={NavIcon3} style={{ fill: 'currentColor' }} />

              <span className="nav-text">Shipping & Exports</span>
            </Link>
          </li>

          <li>
            <Link to="/">
              <Svg src={NavIcon4} />

              <span className="nav-text">Health & Saftey Iot</span>
            </Link>
          </li>

          <li>
            <Link to="/">
              <Svg src={NavIcon5} />
              <span className="nav-text">Monitoring & CCTV</span>
            </Link>
          </li>
          <li
            className={`${window.location.pathname == "/qlik-sense" ? "is-selected" : ""}`}
          >
            <Link to="/qlik-sense">
              <Svg src={NavIcon7} />
              <span className="nav-text" style={{ fontWeight: '400' }}>Qliksense dashboard</span>
            </Link>
          </li>
        </ul>

        <div className="settings">
          <Link to="/">
            <Svg src={NavIcon6} />

            <span className="nav-text">Settings</span>
          </Link>
        </div>
      </nav>

      <div className="main-content-area position-relative">
        {showTopBar && (
          <div className="top-bar">
            {/* <div className="logo">
              <Svg src={ContosoBrand} />
            </div> */}

            <div
              className={`top-bar-item ${topBarItemIsSelected ? 'is-selected' : ''}`}
              onClick={() => {
                if (demoStage <= 2) {
                  setDemoStage(topBarItemIsSelected ? 1 : 2);
                  setTopBarItemIsSelected(!topBarItemIsSelected);
                }
              }}
            >
              <div className="item-image">
                <Svg src={IconTee} />
              </div>
              <span>Refined Products</span>
            </div>

            <div className="top-bar-item">
              <div className="item-image">
                <Svg src={IconPants} />
              </div>
              <span>Oil Production</span>
            </div>

            <div className="top-bar-item">
              <div className="item-image">
                <Svg src={IconJacket} />
              </div>
              <span>Gas Production</span>
            </div>

            <div className="top-bar-item">
              <div className="item-image">
                <Svg src={IconShoe} />
              </div>
              <span>Chemicals</span>
            </div>

            <div className="top-bar-item">
              <div className="item-image">
                <Svg src={IconHat} />
              </div>
              <span>Retail Fuels</span>
            </div>
          </div>
        )}

        <main
          style={{
            flexGrow: 1,
            width: '100%',
            // maxHeight: showTopBar ? 'calc(100vh - 90px)' : 'calc(100vh - 40px)',
          }}
        >
          {children}
        </main>
        <div className={`timer ${isDashboard ? 'dashboard' : ''} ${demoIsLive ? '' : 'history'}`}>
          <Svg src={HistoryIcon} />
          {demoIsLive ? time : demoStage > 3 ? 'IMPACT ASSESSMENT' : 'Order# 442-12448'}
        </div>
      </div>

      {showTeamsToast && (
        <div
          className="teams-toast"
          style={{ opacity: animateToastClose ? 0 : 1 }}
          onClick={() => {
            setAnimateToastClose(true);
            setTimeout(() => {
              setShowTeamsToast(false);
              setAnimateToastClose(false);
            }, 500);
          }}
        >
          <Svg src={TeamsNotification} />
        </div>
      )}
    </div>
  );
};

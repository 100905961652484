import { ReactNode, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Svg from 'react-inlinesvg';

import ContosoBrand from 'assets/ContosoDark.svg';
import Markdown from 'react-markdown';
import styled from 'styled-components';
// import { useReactToPrint } from 'react-to-print';

const ReportHeader = styled.header`
  position: sticky;
  top: 0px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.09);
  background: #fff;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReportBody = styled.main`
  border-radius: 12px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.09);
  background: #fff;
  padding: 16px;
  margin: 16px 0;
`;

const ReportFooter = styled.footer`
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.09);
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 8px;
`;

const ReportPage = () => {
  const { id } = useParams();
  const [markdown, setMarkDown] = useState<string>('');
  const [issuedAt, setIssuedAt] = useState<string>('');
  const [error, setError] = useState<string>('');
  // const contentRef = useRef<HTMLDivElement>(null);
  // const handlePrint = useReactToPrint({
  //   content: () => contentRef.current,
  //   // onBeforePrint: () => {
  //   //   // Adjust styles for printing
  //   //   if (contentRef.current) {
  //   //     contentRef.current.style.overflowY = 'visible';
  //   //     contentRef.current.style.height = 'auto';
  //   //   }
  //   // },
  //   // onAfterPrint: () => {
  //   //   // Revert styles back
  //   //   if (contentRef.current) {
  //   //     contentRef.current.style.overflowY = 'auto';
  //   //     contentRef.current.style.height = '100vh';
  //   //   }
  //   // }
  // });

  useEffect(() => {
    if (id) {
      // Get the report from the local storage
      const markdown = localStorage.getItem(id);
      const issuedAt = localStorage.getItem(`${id}-issuedAt`);
      if (markdown && issuedAt) {
        setMarkDown(markdown);
        setIssuedAt(issuedAt);
      } else {
        setError('Report not found');
      }
    } else {
      setError('Report not found');
    }
  }, [id]);

  useEffect(() => {
    const mainElement = document.querySelector('.ai_widget_section') as any;
    if (mainElement) {
      mainElement.style.display = 'none';
    }
  }, []);

  return (
    // <div ref={contentRef}>
    <div style={{ height: '100vh', overflowY: 'scroll', background: '#f0f4f9', display: 'flex', flexDirection: 'column' }}>
      <ReportHeader>
        <ReportContainer>
          <div></div>
          <div className="logobar" style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '300px', textAlign: 'center' }}>
              <Link to="/" className="nav-brand fulllogo">
                <Svg src={ContosoBrand} />
              </Link>
            </div>
          </div>
          {/* {!error ? <div onClick={handlePrint}>PRINT</div> : <div></div>} */}
        </ReportContainer>
      </ReportHeader>
      <ReportContainer>
        <ReportBody>
          {error ? <div>{error}</div> : markdown ? <Markdown>{markdown}</Markdown> : <div>Loading...</div>}
        </ReportBody>
      </ReportContainer>
      <ReportFooter>
        <h4>Powered By innvatio.io</h4>
        <p>Issued At {issuedAt}</p>
      </ReportFooter>
    </div>
    // </div>
  );
};

const ReportContainer: React.FC<{ children: ReactNode }> = (props) => {
  return <div style={{ width: '100%', maxWidth: '1160px', margin: '0 auto', padding: '8px', flexGrow: '1' }}>{props.children}</div>;
};

export default ReportPage;

import ShoeIcon from 'assets/Icon-Shoe.svg';
import JacketIcon from 'assets/Icon-Jacket.svg';
import PantsIcon from 'assets/Icon-Pants.svg';
import HatIcon from 'assets/Icon-Hat.svg';
import TeeIcon from 'assets/Icon-Tee.svg';

export const productIcons = {
  shoe: ShoeIcon,
  jacket: JacketIcon,
  pants: PantsIcon,
  hat: HatIcon,
  tee: TeeIcon,
};

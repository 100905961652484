import { Gauge } from "components/Gauge";
import moment from "moment";
import ShipmentIcon from "assets/Transport-Ship.svg";
import Svg from 'react-inlinesvg';

interface Props {
  data: any;
  demoIsLive: boolean;
}

const Modal3: React.FC<Props> = ({ data, demoIsLive }) => {
  const { atlanticOceanShipmentData } = data;
  return (
    // <Popup
    //   icon={ShipmentIcon}
    //   title="Twin Type: Shipment"
    //   id={atlanticOceanShipmentData.id}
    //   onClose={() => {
    //     setShowAtlanticOceanShipmentPopup(false);
    //     setShowGaugeData(false);
    //     setSelectedAsset(null);
    //   }}
    //   position={{
    //     top: '40%',
    //     left: '14.2%',
    //   }}
    //   style={{ paddingBottom: 0 }}
    // >
    <div style={{ minWidth: '300px' }}>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="ship-icon">
          <Svg src={ShipmentIcon} />
        </div>
        <div className="title">
          <h3>Twin Type: Shipment</h3>
          <span>ID: {atlanticOceanShipmentData.id}</span>
        </div>
      </header>
      <div style={{ paddingBottom: 5 }}>
        <div className="gauge">
          <Gauge
            value={atlanticOceanShipmentData.humidity}
            min={atlanticOceanShipmentData.minHumidity}
            max={atlanticOceanShipmentData.maxHumidity}
          />
          <div className="title">{demoIsLive ? 'Humidity' : 'AVG Humidity'}</div>
        </div>
        <div className="twin-data">
          {demoIsLive ? (
            <>
              <div>
                <div className="property-title">ETA:</div>
                <div className="property-value">{atlanticOceanShipmentData.estimatedtimeofarrival.format('l LTS')}</div>
              </div>
              <div>
                <div className="property-title">Status:</div>
                <div className="property-value">
                  {atlanticOceanShipmentData.status ? atlanticOceanShipmentData.status : 'On time'}
                </div>
              </div>
              <div>
                <div className="property-title">Location:</div>
                <div className="property-value">
                  {atlanticOceanShipmentData.location ? atlanticOceanShipmentData.location : '39.5771, -40.1922'}
                </div>
              </div>
              <div>
                <div className="property-title">Temp:</div>
                <div className="property-value">
                  {atlanticOceanShipmentData.temperature}
                  {atlanticOceanShipmentData.temperature ? '°F' : ''}
                  <span className="property-info">(Max 100.8°F)</span>
                </div>
              </div>
              <div>
                <div className="property-title">Vibration:</div>
                <div className="property-value">
                  {atlanticOceanShipmentData.vibration}
                  {atlanticOceanShipmentData.vibration ? 'Hz' : ''}
                  <span className="property-info">(Max 160.5Hz)</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="property-title">Delivered On:</div>
                <div className="property-value">{moment().subtract('3', 'days').subtract('1', 'hours').format('l LTS')}</div>
              </div>
              <div>
                <div className="property-title">Status:</div>
                <div className="property-value">Delivered</div>
              </div>
              <div>
                <div className="property-title">AVG Temp:</div>
                <div className="property-value">
                  {atlanticOceanShipmentData.temperature}
                  {atlanticOceanShipmentData.temperature ? '°F' : ''}
                </div>
              </div>
              <div>
                <div className="property-title">AVG Vibration:</div>
                <div className="property-value">
                  {atlanticOceanShipmentData.vibration}
                  {atlanticOceanShipmentData.vibration ? 'Hz' : ''}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="button-full-width">
        <span>View Shipment</span>
      </div>
    </div>
  )
}

export default Modal3;
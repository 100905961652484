import OrderIcon from 'assets/OrderIcon.svg';
import BackIcon from 'assets/Icon-Back.svg';
import Svg from 'react-inlinesvg';
import { productIcons } from 'services/icons';
import RetailIcon from 'assets/Shop.svg';

interface Props {
  data: any;
  demoStage: number;
  setDemoStage: any;
  selectedOrder: any;
  setSelectedOrder: any;
  setDemoIsLive: any;
}

const Modal2: React.FC<Props> = ({ demoStage, data, selectedOrder, setDemoStage, setSelectedOrder, setDemoIsLive }) => {
  const { usRetailData } = data;
  return (
    // <Popup
    //   icon={RetailIcon}
    //   title={selectedOrder ? null : 'Twin Type: Retail'}
    //   id={usRetailData.id}
    //   onClose={
    //     selectedOrder
    //       ? null
    //       : () => {
    //         setShowUSRetailPopup(false);
    //         setSelectedAsset(null);
    //       }
    //   }
    //   position={{
    //     top: selectedOrder ? '41%' : '33%',
    //     left: '7.20%',
    //   }}
    //   width="39vh"
    //   style={{
    //     paddingBottom: 0,
    //   }}
    //   className={demoStage > 3 ? 'impacted' : ''}
    // >
    <div>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="ship-icon">
          <Svg src={RetailIcon} />
        </div>
        <div className="title">
          <h3>Twin Type: Retail</h3>
          <span>ID: {usRetailData.id}</span>
        </div>
      </header>
      {selectedOrder ? (
        <div className="selected-order">
          <Svg
            src={BackIcon}
            className="back"
            onClick={() => {
              setSelectedOrder(null);
            }}
          />
          <div className="order">
            <div className="ship-icon">
              <Svg src={OrderIcon} />
            </div>
            <h5>Order# {selectedOrder.id}</h5>
            <div className="products">
              <span>Product Lines:</span>
              {selectedOrder.products.map((p: 'shoe', i: number) => (
                <Svg src={productIcons[p]} key={p + i} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ flexDirection: 'column' }}>
          <div className="retail-data">
            <div>
              <div className="value">
                <span>{demoStage > 3 ? 'Estimated financial impact' : 'Stock levels'}</span>{' '}
                {demoStage > 3 ? usRetailData.impactedStock : usRetailData.stock}
              </div>
            </div>
            <div>
              <div className="value">
                {' '}
                <span>{demoStage > 3 ? 'Impacted orders' : 'Open orders'}</span>{' '}
                {demoStage > 3 ? usRetailData.impactedOrders : usRetailData.openOrders}
              </div>
            </div>
          </div>
          <div className="orders">
            <h4>{demoStage > 3 ? 'Impacted orders' : 'Recent orders'}:</h4>
            {(demoStage > 3 ? usRetailData.recentOrders.filter((o: any) => o.impacted) : usRetailData.recentOrders).map((o: any) => (
              <div
                key={o.id}
                className="order selectable"
                onClick={() => {
                  if (o.canSelect) {
                    setSelectedOrder(o);
                    setDemoIsLive(false);
                    setDemoStage(3);
                  }
                }}
              >
                <div className="order-retail-icon">
                  <Svg src={OrderIcon} />
                </div>
                <h5>Order# {o.id}</h5>
                {demoStage <= 3 ? (
                  <div className="products">
                    <span>Product Lines:</span>
                    {o.products.map((p: 'shoe', i: number) => (
                      <Svg src={productIcons[p]} key={p + i} style={{ width: 'fit-content' }} />
                    ))}
                  </div>
                ) : (
                  <div style={{ fontSize: 12, marginLeft: 30 }}>Revised ETA: {o.estimatedtimeofarrival}</div>
                )}
              </div>
            ))}
          </div>
          <div className="button-full-width">
            <span>View Outlet</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal2;

// Node_modules
import './Dashboard.scss';
import MapBox from './components/Maps/MapBox/MapBox';
import { Point } from './components/Maps/MapBox/points';

import ShipModal from './components/Modals/ShipModal';
import Modal1 from './components/Modals/Modal1';
import Modal2 from './components/Modals/Modal2';
import Modal3 from './components/Modals/Modal3';

import { useEffect } from 'react';
import Modal4 from './components/Modals/Modal4';
import Modal5 from './components/Modals/Modal5';

import Svg from 'react-inlinesvg';
import IconTee from 'assets/refined.svg';
import IconHat from 'assets/retailsfuels.svg';
import IconJacket from 'assets/gasproduction.svg';
import IconPants from 'assets/oilproduction.svg';
import IconShoe from 'assets/chemicals.svg';
import NavIcon3 from 'assets/shipping.svg';
import NavIcon2 from 'assets/offshore.svg';
import styled from 'styled-components';

const StyledSvg = styled(Svg)`
  fill: #fff !important;
  background-color: #139d48 !important;
  padding: 3px;
  width:30px;
  height:30px;
  border-radius: 50%;
`;

export const Dashboard: React.FC<any> = (props) => {
  const showGaugeData = true;
  // const [selectedAsset, setSelectedAsset] = useState(null);

  const points: Point[] = [
    // GCC Major Cities
    {
      type: 'Feature',
      geometry: {
        coordinates: [55.2708, 25.2048], // Dubai, UAE
        type: 'point',
      },
      marker: <StyledSvg src={IconPants} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [50.5577, 26.2285], // Manama, Bahrain
        type: 'point',
      },
      marker: <StyledSvg src={IconPants} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [47.9774, 29.3759], // Kuwait City, Kuwait
        type: 'point',
      },
      marker: <StyledSvg src={IconTee} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [51.531, 25.2854], // Doha, Qatar
        type: 'point',
      },
      marker: <StyledSvg src={IconJacket} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [46.6753, 24.7136], // Riyadh, Saudi Arabia
        type: 'point',
      },
      marker: <StyledSvg src={IconPants} />,
      minWidth: '350x',
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [58.3829, 23.5859], // Muscat, Oman
        type: 'point',
      },
      marker: <StyledSvg src={IconPants} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },

    // Egypt and Jordan
    {
      type: 'Feature',
      geometry: {
        coordinates: [31.2357, 30.0444], // Cairo, Egypt
        type: 'point',
      },
      marker: <StyledSvg src={IconShoe} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [35.9306, 31.9632], // Amman, Jordan
        type: 'point',
      },
      marker: <StyledSvg src={IconShoe} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },

    // Major World Cities
    {
      type: 'Feature',
      geometry: {
        coordinates: [-74.006, 40.7128], // New York, USA
        type: 'point',
      },
      marker: <StyledSvg src={IconHat} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [139.6917, 35.6895], // Tokyo, Japan
        type: 'point',
      },
      marker: <StyledSvg src={IconHat} />,
      popup: <div>Tokyo, Japan</div>,
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [2.3522, 48.8566], // Paris, France
        type: 'point',
      },
      marker: <StyledSvg src={IconHat} />,
      popup: <div>Paris, France</div>,
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [151.2093, -33.8688], // Sydney, Australia
        type: 'point',
      },
      marker: <StyledSvg src={NavIcon2} />,
      popup: <div>Sydney, Australia</div>,
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [-0.1276, 51.5074], // London, UK
        type: 'point',
      },
      marker: <StyledSvg src={IconHat} />,
      popup: (
        <Modal1
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },

    // Major Shipping Routes
    {
      type: 'Feature',
      geometry: {
        coordinates: [82.1156, -20.2882], // Atlantic Ocean near New York
        type: 'point',
      },
      marker: <StyledSvg src={NavIcon2} />,
      popup: <ShipModal data={props.data} showGaugeData={showGaugeData} />,
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [103.8198, 1.3521], // Singapore Strait
        type: 'point',
      },
      marker: <StyledSvg src={NavIcon3} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [32.5832, 0.3476], // Indian Ocean near Somalia
        type: 'point',
      },
      marker: <StyledSvg src={NavIcon3} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [-122.4194, 37.7749], // Pacific Ocean near San Francisco
        type: 'point',
      },
      marker: <StyledSvg src={NavIcon3} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [55.2708, 25.2048], // Arabian Sea near Dubai
        type: 'point',
      },
      marker: <StyledSvg src={NavIcon3} />,
      popup: (
        <Modal2
          setDemoStage={props.setDemoStage}
          selectedOrder={props.selectedOrder}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoStage={props.demoStage}
          data={props.data}
        />
      ),
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [-35.6796, 39.264],
        type: 'point',
      },
      marker: <StyledSvg src={NavIcon3} />,
      popup: <Modal3 data={props.data} demoIsLive={props.demoIsLive} />,
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [-46.4589, -23.5644],
        type: 'point',
      },
      marker: <StyledSvg src={IconHat} />,
      popup: <Modal4 data={props.data} demoIsLive={props.demoIsLive} />,
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [73.0277, 33.5899],
        type: 'point',
      },
      marker: <StyledSvg src={IconHat} />,
      popup: (
        <Modal5
          data={props.data}
          demoStage={props.demoStage}
          setSelectedOrder={props.setSelectedOrder}
          setDemoIsLive={props.setDemoIsLive}
          demoIsLive={props.demoIsLive}
        />
      ),
    },
  ];

  useEffect(() => {
    const mainElement = document.querySelector('.ai_widget_section') as any;
    if (mainElement) {
      // display: block;
      mainElement.style.display = 'block';
      (document.getElementById('report-generator') as any).innerHTML = 'Dtwin Report Generator';
      (document.getElementById('report-summarization') as any).innerHTML = 'Dtwin Summarization';
      (document.getElementById('report-data-analytics') as any).innerHTML = 'Dtwin Data Analytics';
      (document.getElementById('report-generator-1') as any).innerHTML = 'Dtwin Report Generator-1';
    }
  }, []);

  return (
    <div className="main-content-wrap">
      <MapBox points={points} />
    </div>
  );
  {
    /* // stage={demoStage}
        // onShowPakistanFactoryPopup={() => {
        //   resetPopups();
        //   setShowPakistanFactoryPopup(true);
        //   setSelectedAsset('pakistan-factory');
        //   setTimeout(() => {
        //     setShowGaugeData(true);
        //   }, 200);
        // }}
        // onShowSouthAmericaFactoryPopup={() => {
        //   resetPopups();
        //   setShowSouthAmericaFactoryPopup(true);
        //   setSelectedAsset('south-america-factory');
        //   setTimeout(() => {
        //     setShowGaugeData(true);
        //   }, 200);
        // }}
        // onShowAtlanticOceanShipmentPopup={() => {
        //   resetPopups();
        //   setShowAtlanticOceanShipmentPopup(true);
        //   setSelectedAsset('atlantic-ocean-shipment');
        //   setTimeout(() => {
        //     setShowGaugeData(true);
        //   }, 200);
        // }}
        // onShowIndianOceanShipmentPopup={() => {
        //   resetPopups();
        //   setShowIndianOceanShipmentPopup(true);
        //   setSelectedAsset('indian-ocean-shipment');
        //   setTimeout(() => {
        //     setShowGaugeData(true);
        //   }, 200);
        // }}
        // handleUSStoreClick={() => {
        //   resetPopups();
        //   setShowUSRetailPopup(true);
        //   setSelectedAsset('us-retail');
        // }}
        // handleUKStoreClick={() => {
        //   resetPopups();
        //   setShowUKRetailPopup(true);
        //   setSelectedAsset('uk-retail');
        // }}
        // selectedAsset={selectedAsset} */
  }
};

import React, { useEffect, useRef, useState } from 'react';

// third-party
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// project imports
import CustomMarker from './CustomMarker';
import { Point } from './points';

// CSS
import './Map.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;


interface Props {
  points: Point[];
}

const MapBox: React.FC<Props> = (props) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState<number>(46.2808);
  const [lat, setLat] = useState<number>(24.0213);
  const [zoom, setZoom] = useState<number>(4.45);

  useEffect(() => {
    // if (map.current) return; // initialize map only once
    if (mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom: zoom,
      });

      // Add navigation control (the +/- zoom buttons)
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

      // Add geolocate control to the map.
      map.current.on('move', () => {
        setLng((map.current as any).getCenter().lng.toFixed(4));
        setLat((map.current as any).getCenter().lat.toFixed(4));
        setZoom((map.current as any).getZoom().toFixed(2));
      });

      // Add Custom Markers
      // Render custom marker components
      // geoJson.features.forEach((feature) => {
      // Create a React ref
      //   const ref = React.createRef() as any;
      //   // Create a new DOM node and save it to the React ref
      //   ref.current = document.createElement('div');
      //   // Render a Marker Component on our new DOM node
      //   createRoot(ref.current).render(
      //     <Marker>1</Marker>
      //   );

      //   // Create a Mapbox Marker at our new DOM node
      //   new mapboxgl.Marker(ref.current)
      //     .setLngLat(feature.geometry.coordinates as any)
      //     .addTo(map.current as any);
      // });
    }

    // Clean up on unmount
    return () => {
      if (map && map.current) {
        map.current.remove();
      }
    };
  }, []);

  return (
    <>
      {/* <div className="sidebarStyle">
        <div>
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
      </div> */}
      <div className="map-container" ref={mapContainer} style={{ width: '100%', height: '100%' }}>
        {map.current &&
          props.points.map((feature, index) => (
            <CustomMarker key={index} map={map.current} coordinates={feature.geometry.coordinates} marker={feature.marker} minWidth={feature.minWidth}>
              {feature.popup}
            </CustomMarker>
          ))}
      </div>
    </>
  );
};

export default MapBox;

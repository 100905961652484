import { Gauge } from "components/Gauge";
import OrderIcon from 'assets/OrderIcon.svg';
import Svg from 'react-inlinesvg';
import { productIcons } from "services/icons";

import FactoryIcon from "assets/Factory.svg";

interface Props {
  data: any;
  demoIsLive: boolean;
}

const Modal4: React.FC<Props> = ({ data }) => {
  const { southAmericaFactoryData } = data;

  return (
    // <Popup
    //   icon={FactoryIcon}
    //   title="Twin Type: Factory"
    //   id={southAmericaFactoryData.id}
    //   onClose={() => {
    //     setShowSouthAmericaFactoryPopup(false);
    //     setShowGaugeData(false);
    //     setSelectedAsset(null);
    //   }}
    //   position={{
    //     top: '22%',
    //     left: '7.6%',
    //   }}
    //   width="52vh"
    //   style={{ paddingBottom: 0 }}
    // >
    <div >
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="ship-icon">
          <Svg src={FactoryIcon} />
        </div>
        <div className="title">
          <h3>Twin Type: Factory</h3>
          <span>ID: {southAmericaFactoryData.id}</span>
        </div>
      </header>
      <div style={{ paddingBottom: 20 }}>
        <div className="gauge">
          <Gauge value={southAmericaFactoryData.efficiency} min={southAmericaFactoryData.minEfficiency} max={southAmericaFactoryData.maxEfficiency} />
          <div className="title">Efficiency</div>
        </div>
        <div className="gauge">
          <Gauge value={southAmericaFactoryData.reliability} min={southAmericaFactoryData.minReliability} max={southAmericaFactoryData.maxReliability} />
          <div className="title">Reliability</div>
        </div>
      </div>
      <div className="value-wrap">
        <div className="title">Open Orders {southAmericaFactoryData.openorders}</div>
        {/* <div className="value"></div> */}
      </div>
      <div style={{ flexDirection: 'column' }}>
        <div className="orders">
          <div className="flex space-between v-align">
            <h4>Recent orders:</h4>
            <div className="link">SEE ALL</div>
          </div>
          {southAmericaFactoryData.recentOrders.map((o: any) => (
            <div key={o.id} className="order selectable">
              <div className="order-retail-icon">
                <Svg src={OrderIcon} />
              </div>
              <h5>Order# {o.id}</h5>
              <div className="products" style={{ marginRight: 15 }}>
                {o.products.map((p: 'shoe', i: number) => (
                  <Svg src={productIcons[p]} key={p + i} />
                ))}
              </div>
              <div className="date">{o.date}</div>
              <div className="status">Status: {o.status}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="button-full-width">
        <span>View Factory</span>
      </div>
    </div>
  );
};

export default Modal4
import { Gauge } from 'components/Gauge';
import OrderIcon from 'assets/OrderIcon.svg';
import Svg from 'react-inlinesvg';
import React from 'react';
import { productIcons } from 'services/icons';

import FactoryIcon from "assets/Factory.svg";

interface Props {
  data: any;
  demoStage: number;
  demoIsLive: boolean;
  setDemoIsLive: any;
  setSelectedOrder: any;
}

const Modal5: React.FC<Props> = ({ data, demoStage, setSelectedOrder, demoIsLive, setDemoIsLive }) => {
  const { pakistanFactoryData, southAmericaFactoryData } = data;
  // const navitgate = useNavigate();
  return (
    // <Popup
    //   icon={FactoryIcon}
    //   title="Twin Type: Factory"
    //   id={pakistanFactoryData.id}
    //   onClose={() => {
    //     setShowPakistanFactoryPopup(false);
    //     setShowGaugeData(false);
    //     setSelectedAsset(null);
    //   }}
    //   position={
    //     demoStage > 3
    //       ? {
    //         top: '29%',
    //         left: '50.6%',
    //       }
    //       : {
    //         top: '26%',
    //         left: '30.6%',
    //       }
    //   }
    //   width={demoStage > 3 ? '39vh' : '52vh'}
    //   style={{ paddingBottom: 0 }}
    //   className={demoStage > 3 ? 'impacted' : ''}
    // >
    <div>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="ship-icon">
          <Svg src={FactoryIcon} />
        </div>
        <div className="title">
          <h3>Twin Type: Factory</h3>
          <span>ID: {pakistanFactoryData.id}</span>
        </div>
      </header>
      {demoStage <= 3 ? (
        <>
          <div style={{ paddingBottom: 20 }}>
            <div className="gauge">
              <Gauge
                value={pakistanFactoryData.efficiency}
                min={pakistanFactoryData.minEfficiency}
                max={pakistanFactoryData.maxEfficiency}
              />
              <div className="title">{demoIsLive ? 'Efficiency' : 'AVG Efficiency'}</div>
            </div>
            <div className="gauge">
              <Gauge
                value={pakistanFactoryData.reliability}
                min={pakistanFactoryData.minReliability}
                max={pakistanFactoryData.maxReliability}
              />
              <div className="title">{demoIsLive ? 'Reliability' : 'AVG Reliability'}</div>
            </div>
          </div>
          <div className="value-wrap">
            <div className="title">Open Orders {pakistanFactoryData.openorders}</div>
          </div>
          <div style={{ flexDirection: 'column' }}>
            <div className="orders">
              <div className="flex space-between v-align">
                <h4>Recent orders:</h4>
                <div className="link">SEE ALL</div>
              </div>
              {southAmericaFactoryData.recentOrders.map((o: any) => (
                <div key={o.id} className="order selectable">
                  <div className="order-retail-icon">
                    <Svg src={OrderIcon} />
                  </div>
                  <h5>Order# {o.id}</h5>
                  <div className="products" style={{ marginRight: 15 }}>
                    {o.products.map((p: 'shoe', i: number) => (
                      <Svg src={productIcons[p]} key={p + i} />
                    ))}
                  </div>
                  <div className="date">{o.date}</div>
                  <div className="status">Status: {o.status}</div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="button-full-width"
            onClick={() => {
              setSelectedOrder(null);
              setDemoIsLive(true);
              // navitgate('/factory');
              window.location.href = '/factory';
            }}
          >
            <span>View Factory</span>
          </div>
        </>
      ) : (
        <></>
        // <div
        //   className="button-full-width"
        //   onClick={() => {
        //     setDemoIsLive(true);
        //     setDemoStage(5);
        //     setShowPakistanFactoryPopup(false);
        //     setShowGaugeData(false);
        //     setSelectedAsset(null);
        //   }}
        // >
        //   <span>Stop Line</span>
        // </div>
      )}
    </div>
  );
};

export default Modal5;

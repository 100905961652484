import OrderIcon from 'assets/OrderIcon.svg';
import Svg from 'react-inlinesvg';
import RetailIcon from 'assets/Shop.svg';
import { productIcons } from 'services/icons';

interface Props {
  data: any;
  demoStage: number;
  setSelectedOrder: any;
  setDemoIsLive: any;
}
const Modal1: React.FC<Props> = ({ demoStage, data, setSelectedOrder, setDemoIsLive }) => {
  const { ukRetailData } = data;

  return (
    <div>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="ship-icon">
          <Svg src={RetailIcon} />
        </div>
        <div className="title">
          <h3>Twin Type: Retail</h3>
          <span>ID: {ukRetailData.id}</span>
        </div>
      </header>
      <div style={{ flexDirection: 'column' }}>
        <div className="retail-data">
          <div>
            <div className="value">
              <span>{demoStage > 3 ? 'Estimated financial impact' : 'Stock levels'}</span>{' '}
              {demoStage > 3 ? ukRetailData.impactedStock : ukRetailData.stock}
            </div>
          </div>
          <div>
            <div className="value">
              <span>{demoStage > 3 ? 'Impacted orders' : 'Open orders'}</span>{' '}
              {demoStage > 3 ? ukRetailData.impactedOrders : ukRetailData.openOrders}
            </div>
          </div>
        </div>
        <div className="orders">
          <h4>{demoStage > 3 ? 'Impacted orders' : 'Recent orders'}:</h4>
          {(demoStage > 3 ? ukRetailData.recentOrders.filter((o: any) => o.impacted) : ukRetailData.recentOrders).map((o: any) => (
            <div
              key={o.id}
              className="order selectable"
              onClick={() => {
                setSelectedOrder(o);
                setDemoIsLive(false);
              }}
            >
              <div className="order-retail-icon">
                <Svg src={OrderIcon} />
              </div>
              <h5>Order# {o.id}</h5>
              {demoStage <= 3 ? (
                <div className="products">
                  <span>Product Lines:</span>
                  {o.products.map((p: 'shoe', i: number) => {
                    return <Svg src={productIcons[p]} key={p + i} />;
                  })}
                </div>
              ) : (
                <div className="eta">Revised ETA: {o.estimatedtimeofarrival}</div>
              )}
            </div>
          ))}
        </div>
        <div className="button-full-width">
          <span>View Outlet</span>
        </div>
      </div>
    </div>
  );
};

export default Modal1;
